import { useEffect } from 'react';
import { Spin } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

const { VITE_AUTH0_REDIRECT_URI } = import.meta.env;

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      returnTo: `${VITE_AUTH0_REDIRECT_URI}/user/login`,
    });
  }, []);

  return (
    <div
      id="progress-spin"
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 2,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <Spin style={{ position: 'fixed', marginTop: '200px' }} size="large" />
      </div>
    </div>
  );
};

export default Logout;
