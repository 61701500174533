import './antdStyles.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { setAxiosTokenInterceptor } from './components/Auth0AxiosInterceptor';
import Progress from './components/Progress';
import BasicLayout from './layouts/BasicLayout';
import UserLayout from './layouts/UserLayout';
import Account from './pages/Account';
import Comments from './pages/Comments';
import SuggestedVideo from './pages/Dashboard/SuggestedVideo';
import WeeklyTip from './pages/Dashboard/WeeklyTip';
import CompanyEditor from './pages/DataEditor/CompanyEditor';
import CompanyEventsEditor from './pages/DataEditor/CompanyEventsEditor';
import CompanyMerge from './pages/DataEditor/CompanyMerge';
import InsiderEventsEditor from './pages/DataEditor/InsiderEventsEditor';
import PeopleEditor from './pages/DataEditor/PeopleEditor';
import ProfileEdits from './pages/DataEditor/ProfileEdits';
import Exception from './pages/Exception';
import ContactExport from './pages/ImportExport/ContactExport';
import ContactImport from './pages/ImportExport/ContactImport';
import CrmExportTracks from './pages/ImportExport/CrmExportTracks';
import ReferrerCode from './pages/ReferrerCode';
import Reports from './pages/Reports';
import TeamEditor from './pages/TeamEditor';
import ForgotPassword from './pages/User/ForgotPassword';
import ForgotPasswordSent from './pages/User/ForgotPasswordSent';
import Login from './pages/User/Login';
import Logout from './pages/User/Logout';
import LoginCallBack from './pages/User/LoginCallBack';
import CreditsLog from './pages/UserEditor/CreditsLog';
import UserMappingEditor from './pages/UserEditor/UserMappingEditor';
import UsersEditor from './pages/UserEditor/UsersEditor';
import UserSetupResponse from './pages/UserEditor/UserSetupResponse';
import { requestWithAuth } from './services/api';

const App = () => {
  const [hasToken, setHasToken] = useState(false);
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const [isNexisAdmin, setIsNexisAdmin] = useState(false);

  /**
   * Check if the user is already authenticated
   * Wait untill @isLoading is completed
   * (otherwise @isAuthenticated won't be updated correctly)
   * else if @isAuthenticated get the token and
   * set it in the axios header and after that
   * load the components.
   */
  useEffect(() => {
    const fetchUserData = async () => {
      if (!isLoading && isAuthenticated) {
        try {
          const token = await getIdTokenClaims();
          if (token) setAxiosTokenInterceptor(token.__raw);

          const response = await requestWithAuth(
            'user-type',
            'GET',
            null,
            null,
          );
          if (response?.results?.[0]) {
            setName(response.results[0].full_name);
            setUserType(response.results[0].user_type);
            if (response.results[0].nexis_admin === true) {
              setIsNexisAdmin(true);
            }
          }
          setHasToken(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else if (!isLoading) {
        setHasToken(true);
      }
    };

    fetchUserData();
  }, [isLoading, isAuthenticated, getIdTokenClaims]);

  if (isLoading || !hasToken) return <Progress />;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <BasicLayout
              userName={name}
              userType={userType}
              isNexisAdmin={isNexisAdmin}
            />
          }
        >
          <Route path="data-editor">
            <Route path="vault" element={<PeopleEditor />} />
            <Route path="org-events" element={<CompanyEventsEditor />} />
            <Route path="insider-events" element={<InsiderEventsEditor />} />
            <Route path="company" element={<CompanyEditor />} />
            <Route path="company-merge" element={<CompanyMerge />} />
            <Route path="profile-edits" element={<ProfileEdits />} />
            <Route index element={<Navigate to="/data-editor/vault" />} />
          </Route>
          <Route path="user-editor">
            <Route
              path="users"
              element={<UsersEditor isNexisAdmin={isNexisAdmin} />}
            />
            <Route path="users-mapping" element={<UserMappingEditor />} />
            <Route path="credits-log" element={<CreditsLog />} />
            <Route path="user-setup-response" element={<UserSetupResponse />} />
            <Route index element={<Navigate to="/user-editor/users" />} />
          </Route>

          <Route
            path="referrercode"
            element={<ReferrerCode isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="dashboard">
            <Route path="weekly-tip" element={<WeeklyTip />} />
            <Route path="suggested-video" element={<SuggestedVideo />} />
            <Route index element={<Navigate to="/dashboard/weekly-tip" />} />
          </Route>
          <Route path="comments" element={<Comments />} />
          <Route
            path="team-editor"
            element={<TeamEditor isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="import-export">
            <Route
              path="contact-exports"
              element={<ContactExport isNexisAdmin={isNexisAdmin} />}
            />
            <Route
              path="contact-imports"
              element={<ContactImport isNexisAdmin={isNexisAdmin} />}
            />
            <Route path="crm-export-tracks" element={<CrmExportTracks />} />
            <Route
              index
              element={<Navigate to="/import-export/contact-exports" />}
            />
          </Route>
          <Route path="reports" element={<Reports />} />
          <Route path="account">
            <Route path="settings/base" element={<Account name={name} />} />
            <Route
              path="settings"
              element={<Navigate to="/account/settings/base" />}
            />
            <Route index element={<Navigate to="/account/settings/base" />} />
          </Route>
          <Route
            index
            element={
              <Navigate
                to={isNexisAdmin ? '/user-editor/users' : '/data-editor/vault'}
              />
            }
          />
          <Route path="*" element={<Exception />} />
        </Route>
        <Route path="/user/" element={<UserLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="login/callback" element={<LoginCallBack />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password-sent" element={<ForgotPasswordSent />} />
          <Route path="logout" element={<Logout />} />
          <Route index element={<Navigate to="/user/login" />} />
        </Route>
        <Route path="/exception" element={<Exception />} />
      </Routes>
    </Router>
  );
};

export default App;
